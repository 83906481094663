.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  width: 100%;
  text-align: center;
}

.subtitle {
  font-weight: 500;
}

.heading {
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0;
  margin: 10px 0;
  background-color: #f7f7f7;
  text-align: center;
}

.shop {
  border-bottom: #cdd2e7 1px dashed;
  padding: 10px 0;
  margin: 10px 0;
  font-weight: 500;
  text-align: center;
}

.row {
  padding: 5px 0;
  position: relative;
  font-size: 12px;
  border-bottom: #f7f7f7 1px solid;

  &Value {
    text-align: right;
  }
}

.hint {
  display: inline-block;
}

@media screen and (max-width: 1024px) {
  .row {
    flex-direction: row;
    justify-content: space-between;
  }

  .column {
    width: auto !important;
  }
}

@media print {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin: 25px auto;
    max-width: 300px;
  }

  .heading {
    padding: 5px 0;
    margin: 5px 0;
  }

  .row {
    padding: 3px 0;
  }

  .header button {
    display: none;
  }
}
